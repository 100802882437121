<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Artikel </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateArtikel">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="judul_berita"
                  label="Judul Artikel"
                  placeholder="Judul Artikel"
                />
              </CCol>

              <CCol sm="6">             
                <div class="form-group">
                <label>Jenis Artikel</label>
                  <select class="form-control" v-model="rubrik">
                    <option value="" disabled selected>Pilih Jenis</option>
                    <option v-for="r in rubriks" :value="r.id_rubrik">{{r.nama_rubrik}} </option>
                  </select>
                </div>
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Artikel </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>

              <CCol sm="12">
                <CTextarea
                  v-model="isi_berita"
                  label="Isi Artikel"
                  placeholder="Isi Artikel"
                />  
              </CCol>

            </CRow>
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/artikel">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>      
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      judul_berita : "",
      nama_rubrik : "",
      isi_berita : "",
      gambar_berita : "",
      gambar_berita_text : "",
      rubrik : "",
      placeholder : "Pilih File",
      rubriks : [],
      selected : [], // Must be an array reference!
      show: true,
      modalError:false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showArtikel();
  this.loadData();
  },
  methods: {

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.gambar_berita_text = fileData.name;
      this.gambar_berita = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },

    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"rubriks")
        .then((response) => {
              this.rubriks=response.data;
        })
    },

    updateArtikel: function(event){
      
      const formData = new FormData()

      formData.append('id_berita', this.$route.params.id_berita)
      
      formData.append('judul_berita', this.judul_berita)
      formData.append('id_rubrik', this.rubrik)
      formData.append('isi_berita', this.isi_berita)
      // formData.append('tgl_posting', this.tgl_posting)

      if(this.gambar_berita_text){
        formData.append('gambar_berita', this.gambar_berita, this.gambar_berita.name)
        formData.append('gambar_berita_text', this.gambar_berita.name)
      }

      axios.post(process.env.VUE_APP_BASE_URL+"berita/"+this.$route.params.id_berita, formData)
        .then((response) => {
            if(response.data.status==='success'){
              this.$router.push('/artikel');
            }
            else{
              this.modalError = true;
            }  
        })
    },
    
    showArtikel: function(event){
      // Simple POST request with a JSON body using axios
      const artikel = { 
                          judul_berita:this.judul_berita,
                          nama_rubrik:this.nama_rubrik,
                          // tgl_posting: this.tgl_posting, 
                          isi_berita: this.isi_berita
                          // gambar_berita: this.gambar_berita 
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"berita/"+this.$route.params.id_berita)
        .then((response) => {

              const data = response.data.data;
              this.judul_berita=data.judul_berita;
              this.rubrik=data.id_rubrik;
              // this.tgl_posting=data.tgl_posting;
              this.isi_berita=data.isi_berita;
              // this.gambar_berita=data.gambar_berita; 
              this.placeholder=data.gambar_berita;             
        })
    }
  }
}
</script>